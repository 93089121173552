/* You can add global styles to this file, and also import other style files */


// Global icon font
@import "./assets/fonts/feather/style.css";
//@import "./assets/pulse-icons/css/pulse.css";


// // 3rd party plugins
@import "@mdi/font/css/materialdesignicons.min.css";
//@import "@ng-select/ng-select/themes/default.theme.css";
//@import "metismenujs/scss/metismenujs";
//@import "dropzone/dist/min/dropzone.min.css";
//@import "simple-datatables/dist/style.css";
@import "@swimlane/ngx-datatable/index";
@import "@swimlane/ngx-datatable/themes/bootstrap";
//@import "@swimlane/ngx-datatable/assets/icons";
@import '@ali-hm/angular-tree-component/css/angular-tree-component.css';


// NobleUI styles for LTR
@import "./assets/scss/style";


body {
  font-family: Verdana, serif;
}


.mat-icon {
  // Force the browser to use subpixel anti-aliasing
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: grayscale;

  // Make sure SVG is properly scaled
  svg {
    width: 100%;
    height: 100%;
  }
}

/*.page-header {
  @extend .d-flex, .flex-wrap, .align-items-center, .justify-content-between, .grid-margin;

  > * {
    @extend .d-flex, .align-items-center, .flex-wrap, .text-nowrap;
  }

  .btn:not(:last-child) {
    @extend .me-3;
  }
}*/

.main-wrapper .page-wrapper .page-content {
  //background-color: #FFFFFF;
}

.card-header {
  border-radius: 20px 20px 0 0 !important;
  color: white;
  font-size: 120%;
  font-weight: bold;
  background-color: theme-color(primary);
  text-align: center;
}


.table-hover-effects datatable-body-row {
  //cursor: pointer;

  &:hover {
    background: #d1e2f3 !important;
  }
}

.card {
  border-radius: 8px !important;

  .card-header {
    min-height: 78px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 0;
  }
}

.datatable-scroll-body {
  .datatable-body {
    max-height: 400px;
    overflow-y: scroll;
    overflow-x: hidden; // TODO: Check compatibility and that nothing is hidden in all browsers
  }
}

.card-datatable-sticky-header {
  .card-header {
    border-bottom: 0;
    min-height: 40px;
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
  }

  .card-body {
    @extend .pe-0, .ps-0, .pt-0, .pb-1;
  }

  .ngx-datatable.bootstrap {
    .datatable-header {
      background-color: theme-color(primary);
      color: white;

      .datatable-header-cell {
        padding-top: 7px;
        padding-bottom: 7px;
        border-bottom: none;
      }
    }

    .datatable-row-wrapper:first-child .datatable-body-row {
      border-top: none;
    }
  }


  .datatable-icon-down,
  .datatable-icon-up,
  .datatable-icon-sort-unset {
    width: 16px;
    height: 14px;
  }

  .datatable-icon-down {
    background-image: url('assets/images/datatable/order-down-white.svg');
  }

  .datatable-icon-up {
    background-image: url('assets/images/datatable/order-up-white.svg');
  }

  .datatable-icon-sort-unset {
    background-image: url('assets/images/datatable/order-unordered-white.svg');
  }

}

.ngx-datatable.bootstrap {
  font-size: 100%;
}

.ngx-datatable.datatable-spaced-out {
  .datatable-body .datatable-body-row {
    border-top-color: #e8ebf1;
    border-bottom: 1px solid #e8ebf1;
  }

  datatable-row-wrapper.datatable-row-wrapper {
    margin: 10px 0;

    .datatable-body-row {
      background: white !important;
    }
  }

  .datatable-header .datatable-header-cell {
    border-bottom: none;
    font-weight: bold;
    color: $text-muted;
    padding-bottom: 0;
  }
  .row-qs-icon {
    color: #01B286;
    font-size: 100%;
  }

  .datatable-footer {
    border-top: none;
  }
}

.badge {
  padding: 6px 12px 5px;
  border-radius: 15px;
  font-size: 0.8rem;
}


.datatable-icon-down,
.datatable-icon-up,
.datatable-icon-sort-unset {
  background-position: top left;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 14px;
  height: 12px;
  margin-left: 5px;

  &::before {
    display: none;
  }
}

.datatable-icon-down {
  background-image: url('assets/images/datatable/order-down.svg');
}

.datatable-icon-up {
  background-image: url('assets/images/datatable/order-up.svg');
}

.datatable-icon-sort-unset {
  background-image: url('assets/images/datatable/order-unordered.svg');
}

.dot-success {
  color: theme-color(success);
}

.dot-warning {
  color: theme-color(warning);
}

.dot-gray {
  color: $gray-400;
}



.btn-link {
  text-decoration: underline;
}


.swal-error-alert {
  max-width: 95vw;
  min-height: 100px;
  margin-right: 30px;
  margin-bottom: 40px;
}

.ngx-datatable.bootstrap .datatable-footer .page-count {
  white-space: nowrap; // Avoid scrollbar in footer
}

.icon-folder-closed, .icon-folder-open {
  color: #01B286;
}


.node-wrapper {
  align-items: flex-start;
}


.search-form {
  .input-group-text {
    background: none;
  }

  .input-group-prepend {
    > .input-group-text {
      padding-left: 20px;
      height: 100%;
      //font-size: 120%;
      border-right: 0px none;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-top-left-radius: 25px;
      border-bottom-left-radius: 25px;
      //box-shadow: 0 0px 5px rgba(0,0,0,.15)!important;
    }
  }

  .input-group-append > .input-group-text {
    border-left: 0px none;
    padding-right: 25px;
    font-weight: 600;
    height: 100%;
    //font-size: 120%;
    border-radius: 0;
    //box-shadow: 0 0px 5px rgba(0,0,0,.15)!important;
  }
  .input-group-append:last-child > .input-group-text {
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
    //box-shadow: 0 0px 5px rgba(0,0,0,.15)!important;
  }

  input[type="text"], input[type="search"] {
    border-right: none;
    border-left: none;
    //box-shadow: 0px 5px rgba(0,0,0,.15),0px 5px rgba(0,0,0,.15), 5px 0 white, 5px 0 white !important;
    &:focus {
      //box-shadow: inset 0 -1px 0 #ddd;
      border-top: 1px solid #e2e5ed !important;
      border-bottom: 1px solid #e2e5ed !important;
      border-left: none;
      border-right: none;
    }
  }
}

.form-check-input:checked {
  background-color: var(--bs-success);
  border-color: var(--bs-success);
}

tree-node {
  tree-node-checkbox {
    order: 1;
    .tree-node-checkbox {
      @extend .form-check-input;
      margin-right: 5px;
      margin-top: 6px;
    }
  }
  tree-node-expander {
    display: none;
    order: 0;
    min-width: 20px;
    padding-top: 3px;
  }
  .node-content-wrapper {
    order: 2;
    width: 100%;
  }
}

.tree-show-expander {
  tree-node-expander {
    display: block;
  }
}


.ng-invalid.ng-touched {
  @extend .is-invalid;
}


.icon-small {
  height: 21px !important;
  width: 21px !important;
}
